<template>
    <div class="page-layout-wrapper">
        <div class="container">
            <PageHeader
                title=".WAV POOL"
                subtitle="Log into your account"
            >
               <div
                    v-if="loginFailedMessage"
                    class="text-danger pt-3"
                    v-text="loginFailedMessage"
                />
            </PageHeader>

            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6">
                    <form @submit.prevent="validate">
                        <div class="form-elements">
                            <div class="form-group">
                                <label for="email">Email address</label>
                                <input :class="[{'is-invalid': (validation_errors.email !== '')},'form-control']" v-model="email" type="email" id="email" aria-describedby="emailHelp">
                                <div v-if="validation_errors.email" class="invalid-feedback">{{validation_errors.email}}</div>
                                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input :class="[{'is-invalid': (validation_errors.password !== '')},'form-control']" v-model="password" type="password" id="password">
                                <div v-if="validation_errors.password" class="invalid-feedback">{{validation_errors.password}}</div>
                            </div>
                            <div class="form-group">
                                <button
                                    class="btn btn-block"
                                    v-text="loading ? 'Logging In...' : 'Log In'"
                                />
                            </div>

                            <transition name="fade-fast">
                                <div class="form-elements__blocker" v-show="loading" />
                            </transition>
                        </div>
                    </form>
                    <div class="text-center">
                        <div class="mb-2"><small>Don't have an account?</small></div>
                        <router-link to="/register" class="pill-button pill-button--outline">Register Here</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import PageHeader from '@/components/Page/PageHeader'

export default {
  name: 'Login',
  components: {
    PageHeader
  },
  data: () => ({
    email: '',
    password: '',
    validation_errors: {
      email: '',
      password: ''
    },
    loginFailedMessage: '',
    loading: false
  }),
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin']),
    credentials: function() {
      return {
        email: this.email,
        password: this.password
      };
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async validate() {
      this.loginFailedMessage = ''
      this.loading = true

      await this.login(this.credentials)
        .then(() => {
          const path = this.isAdmin ? '/admin' : '/'

          this.$router.push({ path });
        })
        .catch((data) => {
          if (data.validation_errors != null) {
            Object.entries(data.validation_errors).forEach((el, i) => {
              this.validation_errors[el[0]] = el[1].join(' ')
            })
          }

          this.loginFailedMessage = data.message
        }).finally(() => {
          this.loading = false
        });
    }
  }
}
</script>
